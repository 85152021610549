import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const EarlyMortgagePage = () => (
    <Layout>
        <Seo title={'Nemo Personal Finance | Settle my Nemo second charge mortgage early'} description={'Thinking about settling you Nemo second charge mortgage? For information you should know before you settle your second charge mortgage click here.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/customer-service/moving-home">How your Nemo mortgage
                            works</a></li>
                        <li><a href="/customer-service/remortgaging">What to do if you want to
                            remortgage </a></li>
                        <li><a href="/customer-service/settle-my-second-charge-mortgage" className={'selected'}>Settle
                            my Nemo mortgage early</a></li>
                        <li><a href="/customer-service/update-my-details">Update my details</a>
                        </li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Settle my Nemo mortgage early</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>You can settle your Nemo second charge mortgage at any time.&nbsp; You will need to request a
                            settlement quote from us to know the amount and how to pay.&nbsp;<br /><br />You can request a
                                settlement quote by&nbsp;<a href="/contact-us">contacting us.</a></p>
                        <p>If you are remortgaging and wish to keep your Nemo second charge mortgage you will find more
                            information by <a href="/customer-service/remortgaging">clicking
                                here</a>.&nbsp; </p>
                        <p>
                            <strong>Before settling your second charge mortgage</strong>
                        </p>
                        <p>Your terms and conditions of your second charge mortgage will suggest the early repayment
                            charges or fees to be added to the outstanding balance, which will need to be paid in full
                            in order to settle your Nemo mortgage.&nbsp; This will explain why the value to settle your
                            mortgage is higher than your outstanding balance.&nbsp; </p>
                        <p>If your secured property is in Scotland, you may need to pay additional fees relating to the
                            removal of your charge from the Registrars of Scotland, these fees will be confirmed to you
                            with your settlement figure, and are due in accordance with Scottish Legislation.&nbsp; </p>
                        <p>If you are eligible for Quarterback, this will be forfeited if you settle before the date
                            specified on the certificate sent to you at the start of your mortgage. See our&nbsp;<a
                                href="/faq">FAQ’s</a> for
                            more information. <br /></p>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default EarlyMortgagePage;